<template>
  <el-dialog title="预览图" v-model="dialogVisible" width="80%" center :before-close="handleClose">
    <img :src="url+'?x-oss-process=image/resize,h_800,w_800'" />
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      dialogVisible: false,
      url: ''
    }
  },
  created() {
  },
  methods: {
    init(url) {
      this.url = url
      this.dialogVisible = true
    },

  }
}
</script>
<style lang="scss" scoped>
</style>

