import axios from 'axios'
import { ElMessageBox, ElMessage } from 'element-plus'
import store from '@/store'
import { getStore } from '@/utils/storage'




// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_API,
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    config.headers['Platform'] = 'pc_park_admin'
    config.headers['Authorization'] = 'hd ' + getStore('token')
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.code > 0) {
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 401 || res.code === 402 || res.code === 403) {
        // to re-login
        ElMessageBox.confirm(res.msg, '错误提示', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          store.dispatch('loginOut').then(() => {
            // location.reload()
          })
        })
      } else if (res.code === 407 || res.code === 408) {
        ElMessage.confirm(res.msg, '错误提示', {
          confirmButtonText: '请联系管理员',
          cancelButtonText: '取消',
          type: 'warning'
        })
      } else {
        ElMessage({
          message: res.msg || 'Error',
          type: 'error',
          duration: 5 * 1000
        })
      }
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res.data
    }
  },
  error => {
    console.log('err' + error) // for debug

    ElMessage({
      message: error.response.data.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error.response.data)
  }
)

export const getRequest = function (url, param) {
  return service({
    url: url,
    method: 'get',
    params: param
  })
}

export const postRequest = function (url, param) {
  return service({
    url: url,
    method: 'post',
    data: param
  })
}

export const putRequest = function (url, param) {
  return service({
    url: url,
    method: 'put',
    data: param
  })
}

export const deleteRequest = function (url, param) {
  return service({
    url: url,
    method: 'delete',
    data: param
  })
}